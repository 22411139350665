<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-12 col-xl-6 offset-xl-3 offset-md-3 offset-lg-0">
        <div class="dialogue-box has-header d-flex justify-content-center">
          <div class="dialogue-box-header">Sell Item</div>
          <div class="dialogue-box-inner p-4">
            <div v-if="getItem" class="d-flex justify-content-center flex-column">
              <p>
                Selling item: {{ getItem.Item.name }} x <strong>{{ quantity }}</strong>
              </p>

              <div class="mx-auto">
                <Item :item="getItem" hide-quantity/>
              </div>

              <div class="w-100">
                <input type="range" class="form-range" min="1" :max="getItem.amount" v-model="quantityInput"/>
              </div>

              <form action="" @submit.prevent="listItem">
                <div class="mb-3">
                  <label for="price" class="">Price for each item</label>
                  <div class="input-group">
                    <span class="input-group-text bg-dark" id="basic-addon1"><img src="/img/sprites/coin.png" /></span>
                    <input type="text" id="price" class="form-control bg-dark" v-model="price">
                  </div>
                </div>


                <div class="mb-4">
                  <label for="total_price">Total Price</label>
                  <div class="input-group">
                    <span class="input-group-text bg-dark" id="basic-addon1"><img src="/img/sprites/coin.png" /></span>
                    <input type="text" id="total_price" class="form-control bg-dark" :value="totalPrice" disabled="false">
                  </div>

                </div>
                <div class="">
                  <input type="submit" value="List Item" class="btn btn-primary w-100">
                </div>
              </form>
            </div>
            <div v-else>
              <h4>Select item from your inventory to sell</h4>
              <p>-> Click item</p>
              <p>-> Sell on marketplace</p>
            </div>
          </div>
          <div class="dialogue-bg"></div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item";

export default {
  name: 'MarketplaceSellItem',
  components: {Item},
  data() {
    return {
      price: 0,
      quantityInput: null,
    }
  },
  mounted() {
    if (this.getItem)
      this.price = this.getItem.Item.value;

    this.quantityInput = this.$route.query.amount;
  },
  watch: {
    getItem(o, n) {
      this.quantityInput = this.$route.query.amount;

      if(o) {
        this.price = o.Item.value;
      }

      if(!o || !n) return;

      if (o.Item.id !== n.Item.id) {
        this.price = o.Item.value;
      }
    }
  },
  methods: {
    listItem() {
      this.$socket.emit('marketplace:list item', {
        itemId: this.getItem.id,
        amount: this.quantityInput,
        price: this.price,
      });

      this.$router.push({
        name: 'MarketplaceSellItem'
      });
    }
  },
  computed: {
    ...mapGetters({
      listings: "marketplace/listings",
      inventory: "user/inventory"
    }),
    totalPrice() {
      const totalPrice = this.price * this.quantity

      return new Intl.NumberFormat().format(totalPrice)
    },
    getItem() {
      return this.inventory.find(i => i.id === Number(this.$route.query.item_id))
    },
    quantity() {
      return Number(this.quantityInput) || Number(this.$route.query.amount);
    }
  }
}
</script>